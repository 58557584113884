<template>
    <div class="condition">
        <Header @handleClick="handleClick" :currentTab="currentTab" />
        <div class="header">
            <el-button type="primary" @click="add">添加条件</el-button>
        </div>
        <div class="condition-content">
            <div class="row" v-for="(item, index) in formData.formItemDtoList" :key="index">
                <el-form ref="form" :model="item" inline :rules="rules">
                    <el-row :gutter="20" type="flex" align="middle">
                        <el-col :span="4">
                            <el-form-item label="标题" label-width="60px" prop="formItemName">
                                <el-input v-model="item.formItemName" maxlength="20" placeholder="输入标题"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="类型" label-width="50px" prop="inputType">
                                <el-select v-model="item.inputType" placeholder="请选择类型"
                                    @change="changeType($event, index)">
                                    <el-option v-for="item in ObjToOpt(conditionType)" :key="item.value"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="选项值" label-width="70px" prop="defaultVal"
                                :rules="[{ required: [5, 6].includes(item.inputType), message: '请输入选项值', trigger: 'blur' }]">
                                <el-input v-model="item.defaultVal" maxlength="20" placeholder="以空格间隔"
                                    :disabled="![5, 6].includes(item.inputType)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="摘要展示" label-width="80px" prop="preview" style="display: flex;">
                                <el-switch v-model="item.preview"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <i class="el-icon-delete" @click="deleteRow(item, index)" style="cursor: pointer;"></i>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>

    </div>
</template>
<script>
import Header from './header.vue';
import { yesOrNo, conditionType } from '@/db/objs';
import { ObjToOpt } from '@/utils/utils';
import { updateItems, findItems, delCondition } from "@/api/examine/process.js"

export default {
    components: {
        Header
    },
    data() {
        return {
            ObjToOpt,
            yesOrNo, conditionType,
            currentTab: 1,
            form: {},
            formData: {
                formItemDtoList: [],
            },
            rules: {
                formItemName: [{ required: true, message: '请输入标题', trigger: 'blur' }],
            }
        }
    },
    beforeRouteLeave(to, form, next) {
        //离开该路由之前想进行的操作
        window.localStorage.setItem('processformData', JSON.stringify(this.formData));
        next()
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            this.formData.processId = this.$route.query.processId;
            findItems({ processId: this.$route.query.processId }).then(res => {
                this.formData.formItemDtoList = this.formData.formItemDtoList.concat(res.data);

            })
        },


        // 修改流程
        updateItems() {
            updateItems(this.formData).then(res => {
                if (res.isSuccess == 'yes') {
                    this.$router.push({ path: '/examine/design', query: { processId: this.$route.query.processId } });
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        handleClick(index) {
            switch (index) {
                case 3:
                    if (this.formData.formItemDtoList.length > 0) {
                        let validCount = 0; // 计数器，用于跟踪通过验证的表单数量 
                        this.$refs['form'].forEach((formItem) => {
                            formItem.validate((valid) => {
                                if (valid) {
                                    validCount++; // 如果表单验证通过，计数器加一
                                } else {
                                    return false; // 如果任何一个表单验证失败，直接返回 false
                                }
                            });
                        });
                        // 检查所有表单是否都通过验证
                        if (validCount === this.$refs['form'].length) {
                            // 所有表单都验证通过后，导航到下一个路由
                            this.updateItems();

                        }
                    }else{
                        this.updateItems();
                    }

                    break;
                case 4:
                    // this.$router.go(-1);
                    this.$router.push({ path: '/examine/base', query: { processId: this.$route.query.processId } });
                    break;
                case 5:
                    // 发布流程
                    break;
                default:

                    break;
            }
        },
        add() {
            this.formData.formItemDtoList.push({
                formItemName: '',
                inputType: 1,
                defaultVal: '',
                preview: 1,
            })
        },
        deleteRow(item, index) {
            console.log(item)
            delCondition({ formItemId: item.formItemId }).then(res => {
                if (res.isSuccess == 'yes') {
                    this.$message.success('删除成功');
                    this.formData.formItemDtoList.splice(index, 1);
                }
            })

        },
        changeType(e, i) {
            this.formData.formItemDtoList[i].defaultVal = '';
            this.$refs['form'][i].clearValidate();
        }
    }
}
</script>
<style scoped lang="scss">
.condition {
    padding: 0.15rem;

    /deep/.el-form-item {
        margin-bottom: 0;
    }

    /deep/.el-form-item__content {
        width: 65%;
    }

    /deep/.el-select {
        width: 100%;
    }

    .header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.1rem;
    }

    .row {
        margin-bottom: 0.25rem;
    }

    .condition-content {
        width: 100%;
        padding: 0.1rem 0;
        margin-bottom: 0.1rem;
        height: 600px;
        overflow-x: hidden;

    }

}
</style>